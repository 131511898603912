import { useState, useRef, useEffect, memo } from 'react';
import { LeaguesTournament } from '../../types/types';
import { useEnrollContext } from '../../contexts/Enroll/EnrollContext';
import { RiCheckLine } from '@remixicon/react';

interface TabsProps {
	data: LeaguesTournament[] | undefined;
}

const Carousel = memo(({ data }: TabsProps) => {
	const { select, selectEnroll } = useEnrollContext();
	const tabListRef = useRef<HTMLUListElement>(null);
	const activeTabRef = useRef<HTMLLIElement>(null);

	const handleChange = (data: any) => {
		selectEnroll({
			leagueId: data.league.id,
			leagueName: data.league.title,
			entryAmt: data.entryAmt,
		});
	};

	useEffect(() => {
		if (activeTabRef.current && tabListRef.current) {
			const activeTab = activeTabRef.current;
			const tabList = tabListRef.current;
			const activeTabRect = activeTab.getBoundingClientRect();
			const tabListRect = tabList.getBoundingClientRect();

			if (activeTabRect.left < tabListRect.left) {
				tabList.scrollLeft -= tabListRect.left - activeTabRect.left;
			} else if (activeTabRect.right > tabListRect.right) {
				tabList.scrollLeft += activeTabRect.right - tabListRect.right;
			}
		}
	}, []);

	let isDragging = false;
	let startX: number;
	let scrollLeft: number;

	const handleMouseDown = (e: React.MouseEvent) => {
		isDragging = true;
		startX = e.pageX - (tabListRef.current?.offsetLeft || 0);
		scrollLeft = tabListRef.current?.scrollLeft || 0;
		document.body.style.userSelect = 'none';
	};
	const handleMouseLeave = () => {
		isDragging = false;
		document.body.style.userSelect = '';
	};
	const handleMouseUp = () => {
		isDragging = false;
		document.body.style.userSelect = '';
	};
	const handleMouseMove = (e: React.MouseEvent) => {
		if (!isDragging) return;
		e.preventDefault();
		const x = e.pageX - (tabListRef.current?.offsetLeft || 0);
		const walk = (x - startX) * 2;
		if (tabListRef.current) {
			tabListRef.current.scrollLeft = scrollLeft - walk;
		}
	};

	return (
		<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto">
			<ul
				className="flex gap-2 mb-0 list-none flex-row overflow-x-auto whitespace-nowrap scrollbar-hide"
				role="tablist"
				ref={tabListRef}
				onMouseDown={handleMouseDown}
				onMouseLeave={handleMouseLeave}
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
			>
				{data?.map((tabsTitle, index) => (
					<li
						key={index}
						className="flex flex-shrink-0 cursor-pointer select-none font-bahnschrift"
						ref={
							select.includes(tabsTitle.league.id)
								? activeTabRef
								: null
						}
					>
						<a
							onClick={(e) => {
								e.preventDefault();
								handleChange(tabsTitle);
							}}
							data-toggle="tab"
							href={`#${tabsTitle.league.title}`}
							role="tablist"
							aria-selected={select.includes(tabsTitle.league.id)}
						>
							{tabsTitle.content && (
								<div
									className={
										'w-full h-auto rounded-lg relative ' +
										(select.includes(tabsTitle.league.id)
											? 'border-[3px] border-primary'
											: '')
									}
								>
									<div
										className={
											'absolute right-0 flex w-6 h-6 bg-primary rounded-bl-lg justify-center items-center cursor-pointer ' +
											(select.includes(
												tabsTitle.league.id
											)
												? ''
												: 'hidden')
										}
									>
										<RiCheckLine className="w-4 h-4 justify-center items-center flex text-custom-white1" />
									</div>

									{tabsTitle.content.image ? (
										<img
											className="lg:h-80 w-full h-full object-cover z-0"
											alt={tabsTitle.content.title}
											src={tabsTitle.content.image}
										/>
									) : (
										<div
											className="w-full h-auto"
											dangerouslySetInnerHTML={{
												__html: tabsTitle.content.html,
											}}
										/>
									)}
								</div>
							)}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
});

export default Carousel;
