import { useNavigate } from 'react-router-dom';

interface TableLeagueProps {
	thead: any[];
	tbody: any[];
}

export const TableEnrolls = ({ thead, tbody }: TableLeagueProps) => {
	const navigate = useNavigate();
	if (tbody.length === 0) return <></>;
	return (
		<table className="min-w-full table-auto border-collapse">
			<thead className="border-b border-[#bcc0c4]">
				<tr>
					{thead.map((item: any, index: number) => (
						<th
							key={index}
							className="py-2 px-3 text-center self-stretch text-[#73777c] text-[10px] font-medium font-bahnschrift leading-3 tracking-tight"
						>
							{item.title}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{tbody.map((item: any, index: number) => (
					<tr
						key={index}
						onClick={() => {
							if (item.enrollLeagueId) {
								return navigate(
									`/enroll/${item.enrollLeagueId}`
								);
							}
						}}
						className="h-7"
					>
						<td className="p-2 self-stretch text-black text-[12px] font-medium font-bahnschrift leading-[10px]">
							{item.nick}
						</td>
						<td className="px-3 py-1">
							<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
								<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
									{item.league}
								</div>
							</div>
						</td>
						<td className="px-4 py-1">
							<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
								<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
									{item.winPoints}
								</div>
							</div>
						</td>
						<td className="px-4 py-1 ">
							<div className="py-1 bg-[#73777c] rounded-full justify-center items-center">
								<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
									{item.classification}
								</div>
							</div>
						</td>
						{Number(item.award) > 0 ? (
							<td className="px-3 py-1">
								<div className="px-2 py-1 bg-primary rounded-full justify-center items-center">
									<div className="text-center text-white text-[10px] font-normal font-bahnschrift leading-[10px]">
										R$ {item.award}
									</div>
								</div>
							</td>
						) : (
							<td></td>
						)}
					</tr>
				))}
			</tbody>
		</table>
	);
};
