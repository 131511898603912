import { RiCheckLine } from '@remixicon/react';

const CheckboxIcon = ({ checked, onCheckedChange }: any) => {
	return (
		<div className="flex items-center gap-2 p-0.5 cursor-pointer bg-primary rounded-[5.33px]">
			<label>
				<input
					type="checkbox"
					checked={checked}
					onChange={onCheckedChange}
					className="hidden"
				/>
				<div className="flex items-center justify-center w-3.5 h-3.5">
					{checked && <RiCheckLine size={14} color="#ffffff" />}
				</div>
			</label>
		</div>
	);
};

export default CheckboxIcon;
