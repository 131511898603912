import { useState } from 'react';
import { Layout } from '../../../components/layout';
import { TabsButton } from '../../../components/TabsButton';
import { AllTournament } from './All';
import { MyTournament } from './MyTournament';

export const Tournaments = () => {
	const [filters, setFilters] = useState({
		open: 'Aberto',
		finish: '',
	});
	const [tabs, setTabs] = useState('Todos');

	const handleFilter = (type: string, status: string) => {
		if (type === 'open') {
			setFilters((prev) => ({
				...prev,
				open: prev.open === status ? '' : status,
			}));
		}
		if (type === 'closed') {
			setFilters((prev) => ({
				...prev,
				finish: prev.finish === status ? '' : status,
			}));
		}
	};

	const tabsData = [
		{
			title: 'Todos',
			renderPage: () => (
				<AllTournament
					filter={filters}
					onChangeFilter={handleFilter}
					tabsSelect={tabs}
				/>
			),
		},
		{
			title: 'Meus Torneios',
			renderPage: () => (
				<MyTournament
					filter={filters}
					onChangeFilter={handleFilter}
					tabsSelect={tabs}
				/>
			),
		},
	];

	const onChangeTabs = (tab: string) => {
		setTabs(tab);
	};

	return (
		<Layout>
			<div className="px-4 lg:max-w-screen-xl lg:mx-auto">
				<div className="flex items-center flex-row justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<div></div>
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
						Torneios
					</p>
					<div></div>
				</div>

				<div className="flex flex-col gap-2">
					<TabsButton
						initialPage="Todos"
						render={tabsData}
						onChangeTab={onChangeTabs}
					/>
				</div>
			</div>
		</Layout>
	);
};
