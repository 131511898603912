import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';
import { useEffect, useState } from 'react';
import { useTokenStore } from '../../../store/token';
import { TournamentData } from '../../../types/types';
import { useApi } from '../../../hooks/useApi';
import { generatePrivateKey, sentryCapture } from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import {
	RiCheckboxCircleFill,
	RiCheckLine,
	RiRestartLine,
} from '@remixicon/react';
import Tags from '../../../components/ui/tags';
import CarouselTournament from '../../../components/Carousel/Tournament';
import TabsInTournament from '../../../components/TabsInTournament';
import { ResultsOptions } from '../Tournaments/ResultsOptions';
import TabsButtonLeague from '../../../components/TabsButtonLeague';
import { TableLeague } from '../../../components/TableLeague';
import { ReplyOptions } from '../Tournaments/ReplyOptions';
import { toast } from 'sonner';
import Toast from '../../../components/ui/toast';
import { Games } from '../../../components/Tournaments/Games';

export const EnrollById = () => {
	const { id } = useParams();
	const api = useApi();
	const navigate = useNavigate();
	const token = useTokenStore((state: any) => state.token);
	const [provabilyFair, setProvabilyFair] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData>();

	const fetchEnroll = async () => {
		if (!id) return;
		try {
			setLoading(true);
			const res = await api.getEnroll(id, token);

			if (!res.success) {
				toast(<Toast variant="error">{res.message}</Toast>);
				return navigate('/tournaments');
			}
			setData(res.data);
		} catch (error: any) {
			sentryCapture(error, 'getEnroll', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (location.pathname) {
			fetchEnroll();
		}
	}, [location, id]);

	const Rules = ({ rule }: any) => {
		return (
			<div
				className="w-full h-full z-0 bg-[#f4f4f4] p-2 rounded-lg"
				dangerouslySetInnerHTML={{
					__html: rule.content,
				}}
			/>
		);
	};

	const thead = [
		{
			title: 'Inscrição',
		},
		{
			title: 'Liga',
		},
		{
			title: 'Pontos',
		},
		{
			title: 'Classificação',
		},
		{
			title: 'Prêmio',
		},
	];

	const Participants = ({ participants, leagues }: any) => {
		const leagueArray = leagues.map((item: any) => ({
			title: item.league.title,
			renderPage: () => (
				<TableLeague
					thead={thead}
					tbody={participants.filter(
						(league: any) => league.league === item.league.title
					)}
					tournamentStatus={data?.tournamentStatus}
				/>
			),
		}));

		const render =
			leagues.length > 1
				? [
						{
							title: 'Todos',
							renderPage: () => (
								<TableLeague
									thead={thead}
									tbody={participants}
									tournamentStatus={data?.tournamentStatus}
								/>
							),
						},
						...leagueArray,
				  ]
				: [...leagueArray];

		return (
			<div className="flex flex-col gap-2">
				<TabsButtonLeague
					initialPage={leagues.length > 1 ? 'Todos' : render[0].title}
					render={render}
				/>
			</div>
		);
	};

	return (
		<Layout>
			<div className="px-4 lg:max-w-screen-xl lg:mx-auto">
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<>
						<div className="flex items-center flex-row justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
							<ButtonBack classNameIcon="" />
							<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift">
								{data?.owner
									? 'Minha inscrição'
									: data?.enroll?.nick}
							</p>
							<div></div>
						</div>

						<div className="flex flex-col gap-2 mb-4">
							{data && (
								<div className="flex flex-col gap-2 bg-custom-white1 p-2.5 rounded-lg">
									<div className="w-full bg-[#D4D4D4] flex flex-col gap-2 rounded-lg p-3">
										<Tags status={data.tournamentStatus} />
										<div className="w-full h-[196px]">
											{data?.content ? (
												data.content?.image ? (
													<img
														className="lg:h-80 w-full h-full object-cover z-0"
														alt={data.content.title}
														src={
															data?.content.image
														}
													/>
												) : (
													<div
														className="w-full h-full z-0"
														dangerouslySetInnerHTML={{
															__html: data
																?.content.html,
														}}
													/>
												)
											) : (
												<></>
											)}
											<div
												onClick={() =>
													setProvabilyFair(
														!provabilyFair
													)
												}
												className="w-8 h-8 bg-[#17df6a] rounded-[68px] justify-center items-center float-right inline-flex relative bottom-9 right-2 cursor-pointer"
											>
												<RiCheckLine className="w-6 h-6 text-black" />
											</div>
										</div>
									</div>
									{provabilyFair && (
										<div className="px-4 py-2 bg-[#D4D4D4] rounded-lg flex-col justify-center items-start gap-2.5 inline-flex">
											<div className="self-stretch justify-start items-center gap-2 inline-flex">
												<div className="text-[#a2a5ab] text-[11px] font-semibold font-bahnschrift">
													Provably Fair
												</div>
												<RiCheckboxCircleFill className="w-3.5 h-3.5 relative text-[#449945]" />
											</div>
											<div className="justify-center items-center gap-2 inline-flex">
												<div>
													<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
														Chave gerada no
														servidor:
														<br />
													</span>
													<span className="text-neutral-800 text-[13px] font-semibold font-bahnschrift leading-snug">
														{generatePrivateKey()}
													</span>
												</div>
											</div>
											<div className="self-stretch justify-start items-center gap-2 inline-flex">
												<div>
													<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
														Hash da chave gerada no
														servidor:
														<br />
													</span>
													<span className="text-neutral-800 text-[13px] font-semibold font-bahnschrift leading-snug">
														{generatePrivateKey()}
													</span>
												</div>
											</div>
											<div className="self-stretch justify-start items-center gap-2 inline-flex">
												<div>
													<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift">
														Chave escolhida pelo
														usuário:
														<br />
													</span>
													<span className="text-neutral-800 text-[13px] font-semibold font-bahnschrift">
														{generatePrivateKey()}
													</span>
												</div>
											</div>
										</div>
									)}
									<CarouselTournament
										data={data?.leaguesTournament
											.filter(
												(league) => league.isSelected
											)
											.sort((a: any, b: any) => {
												return a.sequence - b.sequence;
											})}
									/>

									<TabsInTournament
										initialPage=""
										render={[
											{
												title: 'Resultados',
												renderPage: () => (
													<ResultsOptions
														data={data}
													/>
												),
											},
											{
												title: 'Jogos',
												renderPage: () => (
													<Games
														eventsTournament={
															data.eventsTournament
														}
													/>
												),
											},
											{
												title: 'Regras',
												renderPage: () => (
													<Rules rule={data.rule} />
												),
											},
											{
												title: 'Participantes',
												renderPage: () => (
													<Participants
														participants={
															data.participants
														}
														leagues={data?.leaguesTournament.sort(
															(
																a: any,
																b: any
															) => {
																return (
																	a.sequence -
																	b.sequence
																);
															}
														)}
													/>
												),
											},
										]}
									/>

									<ReplyOptions data={data} />
								</div>
							)}
						</div>
					</>
				)}
			</div>
		</Layout>
	);
};
