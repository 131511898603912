import { useContext } from 'react';
import { Logo, LogoNegative } from '../Logo';
import Button from '../ui/button';

import LoginAndRegister from './LoginAndRegister';
import { AuthContext } from '../../contexts/Auth/AuthContext';
import { useBalanceStore } from '../../store/balance';
import { RiQuestionnaireFill } from '@remixicon/react';
import HideBalance from '../ui/HideBalance';
import { useNavigate } from 'react-router-dom';

declare global {
	interface Window {
		zE?: any;
	}
}

const Header = ({ logoWhite }: any) => {
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const { showAndHideBalance } = useBalanceStore();
	const currentBalance = useBalanceStore((state) => state.balance);
	const hideBalance = useBalanceStore((state) => state.hideBalance);
	const classHelpLogged = auth.user ? '' : 'lg:hidden';

	const helpButton = () => (
		<Button
			// onClick={() => window.zE('messenger', 'open')}
			onClick={() => window.zE('webWidget', 'open')}
			variant="help"
			className={`lg:p-2.5 ${classHelpLogged}`}
		>
			<RiQuestionnaireFill
				size={25}
				color="#000000"
				className={`flex-grow-0 flex-shrink-0 p-1 lg:p-0 justify-between`}
			/>
		</Button>
	);

	return (
		<div
			className={`flex items-center gap-2 p-2 lg:max-w-screen-xl lg:mx-auto justify-between`}
		>
			<a
				onClick={() => {
					navigate('/');
				}}
				className="cursor-pointer"
			>
				{logoWhite ? <LogoNegative /> : <Logo />}
			</a>

			{auth.user ? (
				<div className="flex flex-row justify-items-end gap-2">
					{helpButton()}

					<div className="flex justify-between items-center flex-row gap-[26px] col-span-4">
						<Button
							className="px-3.5 py-3 font-bahnschrift text-center"
							onClick={() => {
								showAndHideBalance(!hideBalance);
							}}
						>
							R$ {hideBalance ? '***.**' : currentBalance}
							<HideBalance />
						</Button>
					</div>
				</div>
			) : (
				<div className="flex flex-row justify-items-end gap-2">
					{helpButton()}

					<LoginAndRegister />
				</div>
			)}
		</div>
	);
};

export default Header;
