import React, {
	createContext,
	useState,
	useContext,
	ReactNode,
	useEffect,
} from 'react';
import { useApi } from '../../hooks/useApi';
import { useTokenStore } from '../../store/token';
import {
	areMandatoryQuestionsAnswered,
	sentryCapture,
} from '../../helpers/utils';
import { SentryError } from '../../types/enums';
import Toast from '../../components/ui/toast';
import { toast } from 'sonner';
import Button from '../../components/ui/button';
import ModalEnroll from '../../components/Modal/Enroll';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBalanceStore } from '../../store/balance';
import { Warning } from '../../components/Icons/Warning';
import { useModalBalanceAlertStore } from '../../store/modalBalanceAlert';
import numeral from 'numeral';

interface EnrollContextType {
	enroll: any;
	select: any;
	enrollOptions: any;
	tournament: any;
	optionTournament: any;
	optionSelection: number;
	selectEnroll: (item: any) => void;
	enrollOption: (data: any) => void;
	initialSelectEnroll: (item: any) => void;
	depositReturnEnroll: (item: any) => void;
	SetOptionTournament: (data: any) => void;
	SetTournament: (data: any) => void;
	SetOptionSelection: (value: number) => void;
	SetLeague: (data: any) => void;
	SetError: (message: string) => void;
	openModalEnroll: () => void;
	closeModalEnroll: () => void;
	clearEnroll: () => void;
	clearOptionTournament: () => void;
	clearTournament: () => void;
	clearOptionSelection: () => void;
	clearLeague: () => void;
}

export const EnrollContext = createContext<EnrollContextType | undefined>(
	undefined
);

interface EnrollProviderProps {
	children: ReactNode;
}

export const EnrollProvider: React.FC<EnrollProviderProps> = ({ children }) => {
	const api = useApi();
	const navigate = useNavigate();
	const location = useLocation();
	const { modal, openModal, closeModal, enrollsInfoModal } =
		useModalBalanceAlertStore();
	const currentBalance = useBalanceStore((state) => state.balance);
	const token = useTokenStore((state: any) => state.token);

	const [isLoading, setIsLoading] = useState(false);
	const [changedModal, setChangedModal] = useState(false);
	const [buttonShow, setButtonShow] = useState(false);
	const [optionSelection, setOptionSelection] = useState<number>(0);
	const [tournament, setTournament] = useState<any>();
	const [league, setLeague] = useState<any[]>([]);
	const [optionTournament, setOptionTournament] = useState<string[]>([]);
	const [select, setSelect] = useState<string[]>([]);
	const [enroll, setEnroll] = useState<any[]>([]);
	const [enrollOptions, setEnrollOptions] = useState<string[]>([]);
	const entryAmt = enroll.reduce((acc, league) => {
		return acc + parseFloat(league.entryAmt);
	}, 0);
	const [disableButton, setDisableButton] = useState(true);
	const [error, setError] = useState('');

	useEffect(() => {
		if (league.length > 0) {
			const pattern = /^\/tournaments\/[a-fA-F0-9-]{36}$/;
			if (pattern.test(location.pathname)) {
				if (league.length > 0) {
					setButtonShow(true);
				}
			} else {
				clearAllState();
				setButtonShow(false);
			}
		}
	}, [location.pathname, league]);

	const clearAllState = () => {
		setEnroll([]);
		setEnrollOptions([]);
		setOptionTournament([]);
		setTournament(undefined);
		setOptionSelection(0);
		setSelect([]);
		setLeague([]);
		setChangedModal(false);
	};

	const SetError = (message: string) => {
		setError(message);
	};

	const enrollOption = (data: any) => {
		setEnrollOptions(data);
	};
	const SetOptionTournament = (data: any) => {
		setOptionTournament(data);
	};
	const SetTournament = (data: any) => {
		setTournament(data);
	};
	const SetOptionSelection = (value: number) => {
		setOptionSelection(value);
	};
	const SetLeague = (data: any) => {
		setLeague(data);
	};
	const clearEnroll = () => {
		setEnroll([]);
	};
	const clearOptionTournament = () => {
		setOptionTournament([]);
	};
	const clearTournament = () => {
		setTournament({});
	};
	const clearLeague = () => {
		setLeague([]);
	};
	const openModalEnroll = () => {
		setChangedModal(true);
	};
	const clearOptionSelection = () => {
		setOptionSelection(0);
	};
	const closeModalEnroll = () => {
		setChangedModal(false);
	};
	const selectEnroll = (item: any) => {
		setSelect((prevSelect) => {
			if (prevSelect.includes(item.leagueId)) {
				return prevSelect.filter((id) => id !== item.leagueId);
			} else {
				return [...prevSelect, item.leagueId];
			}
		});
		setEnroll((prevOptions: any) => {
			const exists = prevOptions.some(
				(league: any) => league.leagueId === item.leagueId
			);
			if (exists) {
				const updatedOptions = prevOptions.filter(
					(league: any) => league.leagueId !== item.leagueId
				);
				return updatedOptions;
			} else {
				return [...prevOptions, item];
			}
		});
	};
	const initialSelectEnroll = (item: any) => {
		setSelect([item.leagueId]);
		setEnroll([item]);
	};
	const depositReturnEnroll = (item: any) => {
		setSelect(item.map((select: any) => select.leagueId));
		setEnroll(item);
	};

	const entryLeague = async (leagueId: string) => {
		try {
			const res = await api.findEnroll(
				leagueId,
				tournament.tournamentId,
				token
			);
			if (!res?.success) {
				throw new Error(res.message);
			}
			return res;
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'entryLeagueValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSave = async () => {
		setIsLoading(true);
		for (const findEnroll of enroll) {
			const findLeague = league.find(
				(id) => id.league.id === findEnroll.leagueId
			);

			if (!findLeague.multipleEntry) {
				const res = await entryLeague(findLeague.league.id);
				if (!res?.success) {
					setIsLoading(false);
					return;
				}
			}
		}

		if (parseFloat(currentBalance) < entryAmt) {
			openModal(true);
			closeModalEnroll();
			setIsLoading(false);
			return;
		}

		try {
			const body = {
				tournamentId: tournament.tournamentId,
				entryAmt,
				leagues: enroll,
				optionsSelect: enrollOptions,
			};

			const res = await api.createNewEnroll(body, token);
			if (!res?.success) {
				throw new Error(res.message);
			}
			toast(
				<Toast variant="success">Entrada Realizada! Boa sorte!</Toast>
			);
			clearEnroll();
			clearTournament();
			clearOptionTournament();
			setEnrollOptions([]);
			navigate('/tournaments');
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'sendTokenValidation', SentryError.Error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (enrollOptions.length !== optionSelection) {
			setDisableButton(true);
			if (enrollOptions.length === 0) {
				setError(
					`Responda ${optionSelection} perguntas para participar`
				);
			} else {
				setError(
					`${enrollOptions.length} de ${optionSelection} perguntas respondida`
				);
			}
			return;
		}
		if (!areMandatoryQuestionsAnswered(optionTournament, enrollOptions)) {
			setDisableButton(true);
			setError('Responda as perguntas obrigatórias.');
			return;
		}
		setDisableButton(false);
		setError('');
	}, [enroll, enrollOptions]);

	return (
		<EnrollContext.Provider
			value={{
				enroll,
				select,
				enrollOptions,
				optionTournament,
				tournament,
				optionSelection,
				selectEnroll,
				enrollOption,
				SetOptionTournament,
				SetTournament,
				SetOptionSelection,
				SetLeague,
				openModalEnroll,
				closeModalEnroll,
				clearEnroll,
				clearOptionTournament,
				clearTournament,
				clearOptionSelection,
				initialSelectEnroll,
				depositReturnEnroll,
				clearLeague,
				SetError,
			}}
		>
			{children}

			{buttonShow && (
				<div className="fixed flex items-center justify-center bottom-[80px] left-0 right-0 px-3.5 w-full lg:max-w-screen-xl lg:mx-auto duration-500 transform">
					<Button
						className="w-full px-5 py-3"
						onClick={openModalEnroll}
						disabled={disableButton}
					>
						{error ? error : 'Confirmar inscrição'}
					</Button>
				</div>
			)}

			<ModalEnroll
				id="modalenroll"
				open={changedModal}
				onClose={closeModalEnroll}
			>
				<div className="flex flex-col gap-6">
					<div className="flex flex-col">
						<div className="self-stretch text-primary text-xl font-bold font-bahnschrift pb-2.5">
							Confirme sua inscrição
						</div>

						{enroll.length > 0 && (
							<>
								<div className="text-black text-xs font-normal font-bahnschrift pb-2">
									Ligas selecionadas:
								</div>
								<div className="flex flex-col gap-2 pb-4">
									{enroll.map((enroll, index) => (
										<div
											key={index}
											className="flex px-2.5 py-2 bg-black rounded-lg border border-primary justify-between items-center bg-icon-league bg-no-repeat bg-right"
											onClick={() => {
												selectEnroll({
													leagueId: enroll.leagueId,
													leagueName:
														enroll.leagueName,
													entryAmt: enroll.entryAmt,
												});
											}}
										>
											<div className="text-white text-sm font-bold font-bahnschrift leading-snug">
												{enroll.leagueName}
											</div>

											<div className="flex flex-row gap-0.5">
												<span className="text-white text-[8px] font-bahnschrift leading-[14px]">
													R$
												</span>
												<span className="text-white text-[10px] font-bahnschrift leading-[14px]">
													{enroll.entryAmt}
												</span>
											</div>
										</div>
									))}
								</div>
							</>
						)}

						{league.filter(
							(league: any) =>
								!enroll.some(
									(exclude) =>
										exclude.leagueId === league.league.id
								)
						).length !== 0 && (
							<>
								<div className="text-black text-xs font-normal font-bahnschrift pb-2">
									Ligas Disponiveis:
								</div>
								<div className="flex flex-col gap-2 pb-4">
									{league
										.filter(
											(league: any) =>
												!enroll.some(
													(exclude) =>
														exclude.leagueId ===
														league.league.id
												)
										)
										.map((league, index) => (
											<div
												key={index}
												className="flex px-2.5 py-2 bg-[#727272] rounded-lg justify-between items-center bg-icon-league bg-no-repeat bg-right"
												onClick={() => {
													selectEnroll({
														leagueId:
															league.league.id,
														leagueName:
															league.league.title,
														entryAmt:
															league.entryAmt,
													});
												}}
											>
												<div className="text-white text-sm font-bold font-bahnschrift leading-snug">
													{league.league.title}
												</div>

												<div className="flex flex-row gap-0.5">
													<span className="text-white text-[8px] font-bahnschrift leading-[14px]">
														R$
													</span>
													<span className="text-white text-[10px] font-bahnschrift leading-[14px]">
														{league.entryAmt}
													</span>
												</div>
											</div>
										))}
								</div>
							</>
						)}

						<div>
							<span className="text-black text-xs font-bold font-bahnschrift">
								Total a pagar:
							</span>
							<span className="text-black text-xs font-normal font-bahnschrift">
								{' '}
								R$ {numeral(entryAmt).format('0,0.00')}
							</span>
						</div>
					</div>

					<Button
						className="w-full px-5 py-3"
						textCustom="text-custom-white"
						isLoading={isLoading}
						onClick={handleSave}
						disabled={enroll.length === 0}
					>
						{enroll.length === 0
							? 'Selecione ao menos uma liga'
							: 'Confimar inscrição'}
					</Button>
				</div>
			</ModalEnroll>

			<ModalEnroll id="modalBalance" open={modal} onClose={closeModal}>
				<div className="flex flex-col gap-6 items-center">
					<Warning />

					<p className="w-80 text-sm text-center text-custom-gray2 font-bahnschrift">
						O saldo da sua carteira é de R${' '}
						{parseFloat(currentBalance).toFixed(2)}, deseja efetuar
						um depósito?
					</p>

					<div className="flex flex-row justify-between gap-2 w-full">
						<Button
							onClick={closeModal}
							variant="help"
							textCustom="text-primary text-sm font-semibold"
							className="w-20 py-2 px-3.5"
						>
							Não
						</Button>
						<div className="w-full">
							<Button
								onClick={() => {
									closeModal();
									enrollsInfoModal(
										`/tournaments/${tournament.tournamentId}`,
										entryAmt - parseFloat(currentBalance),
										enroll,
										enrollOptions
									);
									navigate(`/wallet/deposit`);
								}}
								className={'w-full h-12'}
							>
								Efetuar depósito
							</Button>
						</div>
					</div>
				</div>
			</ModalEnroll>
		</EnrollContext.Provider>
	);
};

export const useEnrollContext = (): EnrollContextType => {
	const context = useContext(EnrollContext);
	if (context === undefined) {
		throw new Error(
			'useEnrollContext must be used within a EnrollProvider'
		);
	}
	return context;
};
