import TextAreaInput from '../../../../components/ui/Textarea';
import TextInput from '../../../../components/ui/TextInput';
import { useUserProfileStore } from '../../../../store/user';
import { SendDocument } from '../ProfileInfo/Edit/Document';
import * as DateFns from 'date-fns';

export const DocumentsInfo = () => {
	const userData = useUserProfileStore((state) => state.userData);

	let dateformat;

	if (userData?.birthAt) {
		const birthAt = new Date(userData?.birthAt || '' + 'T00:00:00');
		dateformat = DateFns.format(birthAt, 'dd/MM/yyyy');
	}

	return (
		<div className="flex flex-col gap-6 pb-6">
			{userData?.name && (
				<div className="flex-1">
					<TextInput
						type="text"
						placeholder="Nome"
						value={userData?.name}
						onChange={() => {}}
						disabled
					/>
				</div>
			)}
			{userData?.documentNumber && (
				<div className="flex-1">
					<TextInput
						type="text"
						placeholder="CPF"
						value={userData?.documentNumber || ''}
						onChange={() => {}}
						disabled
					/>
				</div>
			)}
			{userData?.birthAt && (
				<div className="flex-1">
					<TextInput
						type="text"
						placeholder="Data de nascimento"
						value={userData?.birthAt ? dateformat : ''}
						onChange={() => {}}
						disabled
					/>
				</div>
			)}
			{userData?.address && (
				<div className="flex-1">
					<TextAreaInput
						placeholder="Endereço"
						value={userData?.address || ''}
						disabled
					/>
				</div>
			)}

			<SendDocument tabsDocuments />

			<div>
				<p className="text-xs font-semibold text-left text-custom-gray2 pb-2 font-bahnschrift">
					Documentos enviados
				</p>
				<div className="flex flex-col gap-4">
					{[1, 2, 3].map((_, index) => (
						<div className="w-full py-3 px-4 rounded-[37px] bg-custom-gray7 border border-custom-gray4 border-dashed flex flex-row items-center justify-between">
							<p className="text-xs text-left text-custom-gray2 font-bahnschrift">
								Enviado em 28/11/2023 | 22:30
							</p>
							<div className="flex justify-center items-center gap-1 px-2 py-1 rounded-[36px] bg-custom-gray6">
								<p className="flex-grow-0 flex-shrink-0 text-xs font-semibold text-left text-custom-gray2 font-bahnschrift">
									Pendente
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
