import { useRef } from 'react';
import OptionItem from '../../../../components/Tournament/OptionItem';

export const Options = ({
	data,
	optionsSelect,
	countSelect,
	handleSelectOption,
}: any) => {
	if (!data) return null;

	const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
	const handleOptionSelect = (id: any) => {
		handleSelectOption(id);

		if (id.delete) return;
		const selectedIndex = data.optionsTournament.findIndex(
			(option: any) => option.id === id.optionId
		);
		if (optionRefs.current[selectedIndex]) {
			optionRefs.current[selectedIndex]?.scrollIntoView({
				behavior: 'smooth',
			});
			optionRefs.current[selectedIndex]?.focus();
			const offset = 44;
			const topPosition =
				optionRefs.current[selectedIndex]?.getBoundingClientRect()
					.top || 0;
			window.scrollBy({
				top: topPosition - offset,
				behavior: 'smooth',
			});
		}
	};

	return (
		<div className="flex flex-col gap-1.5">
			{data.optionsTournament.map((options: any, index: number) => (
				<OptionItem
					key={index}
					item={options}
					index={options.id}
					optionsSelect={optionsSelect}
					blockSelection={
						countSelect === parseInt(data.optionSelection)
					}
					playersTeams={data.players}
					onChange={handleOptionSelect}
					ref={(el) => (optionRefs.current[index] = el)}
				/>
			))}
		</div>
	);
};
