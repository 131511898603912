import { useState, useRef, useEffect } from 'react';

interface TabsProps {
	initialPage: string;
	onChangeTab: (tab: string) => void;
	render: {
		title: string;
		renderPage: () => JSX.Element;
	}[];
}

export const TabsButton = ({ render, initialPage, onChangeTab }: TabsProps) => {
	const [openTab, setOpenTab] = useState(initialPage);
	const firstItem = render[0];

	return (
		<div className="flex flex-col w-full lg:max-w-screen-xl lg:mx-auto lg:pt-4 gap-4">
			<ul
				className="w-full flex flex-row items-center mb-0 list-none rounded-lg border border-primary"
				role="tablist"
			>
				{render.map((tabsTitle, index) => (
					<li
						key={index}
						className={
							'flex w-1/2 items-center justify-center gap-2 px-3.5 py-2 flex-shrink-0 cursor-pointer select-none font-bahnschrift ' +
							(openTab === tabsTitle.title
								? 'bg-primary '
								: 'text-[#696969] ') +
							(firstItem.title === tabsTitle.title
								? 'rounded-tl rounded-bl'
								: 'rounded-tr rounded-br')
						}
						onClick={(e) => {
							e.preventDefault();
							setOpenTab(tabsTitle.title);
							onChangeTab(tabsTitle.title);
						}}
					>
						<a
							className={
								'text-sm px-5 font-semibold text-left font-bahnschrift ' +
								(openTab === tabsTitle.title
									? 'text-white'
									: 'text-[#696969]')
							}
							data-toggle="tab"
							href={`#${tabsTitle.title}`}
							role="tablist"
							aria-selected={openTab === tabsTitle.title}
						>
							{tabsTitle.title}
						</a>
					</li>
				))}
			</ul>

			<div className="relative flex flex-col min-w-0 break-words w-full">
				<div className="flex-auto">
					{render.map((tabsRender, index) => (
						<div className="tab-content tab-space" key={index}>
							<div
								className={
									openTab === tabsRender.title
										? 'block'
										: 'hidden'
								}
								id={tabsRender.title}
							>
								{tabsRender.renderPage()}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
