import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/layout';
import { useTokenStore } from '../../../store/token';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	areMandatoryQuestionsAnswered,
	sentryCapture,
	transformInitial,
} from '../../../helpers/utils';
import { SentryError } from '../../../types/enums';
import { useApi } from '../../../hooks/useApi';
import { TournamentData } from '../../../types/types';
import { RiRestartLine } from '@remixicon/react';
import TabsInLine from '../../../components/TabsInLine';
import Carousel from '../../../components/Carousel';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';
import { useEnrollContext } from '../../../contexts/Enroll/EnrollContext';
import ButtonBack from '../../../components/ui/buttonBack';
import { Options } from './Option';
import { toast } from 'sonner';
import Toast from '../../../components/ui/toast';
import { useModalBalanceAlertStore } from '../../../store/modalBalanceAlert';

export const TournamentById = () => {
	const { id } = useParams();
	const api = useApi();
	const {
		enroll,
		enrollOptions,
		optionTournament,
		optionSelection,
		initialSelectEnroll,
		enrollOption,
		SetOptionTournament,
		SetOptionSelection,
		SetLeague,
		SetTournament,
		openModalEnroll,
		depositReturnEnroll,
	} = useEnrollContext();
	const {
		urlAction,
		leagues,
		optionsSelect: optionSelected,
		clearEnrollsInfoModal,
	} = useModalBalanceAlertStore();
	const token = useTokenStore((state: any) => state.token);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData | null>(null);
	const [participants, setParticipants] = useState<any>([]);
	const [optionsSelect, setOptionsSelect] = useState<any>([]);
	const [countSelect, setCountSelect] = useState(0);

	useEffect(() => {
		fetchTournament();
	}, []);

	useEffect(() => {
		enrollOption(optionsSelect);
		setCountSelect(optionsSelect.length);
	}, [optionsSelect]);

	useEffect(() => {
		if (!data) return;
		if (
			!areMandatoryQuestionsAnswered(optionTournament, enrollOptions) &&
			countSelect === optionSelection
		) {
			const handler = setTimeout(() => {
				toast(
					<Toast variant="error">
						Responda as perguntas obrigatórias.
					</Toast>
				);
			}, 1000);
			return () => clearTimeout(handler);
		} else {
			if (enroll.length > 0 && countSelect === optionSelection) {
				setTimeout(() => {
					openModalEnroll();
				}, 2000);
			}
		}
	}, [enroll, enrollOptions]);

	const fetchTournament = async () => {
		if (!id) return;
		try {
			setLoading(true);
			const res = await api.getTournament(id, token);

			if (!res?.success) {
				throw new Error(res.message);
			}
			setData(res.data);
			SetTournament({
				tournamentId: id,
			});
			setParticipants(res.data.participants || []);
			SetLeague(res.data.leaguesTournament);
			SetOptionTournament(res.data.optionsTournament);
			SetOptionSelection(parseInt(res.data.optionSelection));

			if (urlAction) {
				setOptionsSelect(optionSelected);
				enrollOption(optionSelected);
				depositReturnEnroll(leagues);
				clearEnrollsInfoModal();
				openModalEnroll();
			} else {
				initialSelectEnroll({
					leagueId: res.data.leaguesTournament[0].league.id,
					leagueName: res.data.leaguesTournament[0].league.title,
					entryAmt: res.data.leaguesTournament[0].entryAmt,
				});
			}
		} catch (error: any) {
			sentryCapture(error, 'getTournament', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const tabsData = [
		{
			title: 'Perguntas',
			renderPage: () => (
				<Options
					data={data}
					countSelect={countSelect}
					optionsSelect={optionsSelect}
					handleSelectOption={handleSelectOption}
				/>
			),
		},
		{ title: 'Jogos', renderPage: () => <Games /> },
		{ title: 'Regras', renderPage: () => <Rules /> },
		{ title: 'Participantes', renderPage: () => <Participants /> },
	];
	const Games = () => {
		if (!data) return null;
		return (
			<div className="grid gap-4 lg:grid-cols-3">
				{data?.eventsTournament.map((event, index) => (
					<div className="flex flex-col" key={index}>
						<div className="flex-row h-[38px] rounded-t-lg px-4 py-2 bg-primary justify-between items-center inline-flex">
							<div className="text-custom-white text-xs font-bold font-bahnschrift leading-snug">
								{event.event.category.name}
							</div>
							<div className="text-custom-white text-xs font-bold font-bahnschrift leading-snug">
								{format(
									event.event.startAt.replace('Z', ''),
									"dd/MM • EEE • HH'h'mm",
									{ locale: ptBR }
								)}
							</div>
						</div>
						<div className="h-[64.11px] px-4 py-2 rounded-b-lg bg-custom-white1 justify-between items-center inline-flex">
							<div className="flex flex-row items-center gap-2">
								<img
									className="w-10 h-10 object-scale-down"
									src={event.teamHome.image}
									alt={event.teamHome.name}
								/>
								<span className="text-custom-black text-xs font-bold font-bahnschrift leading-snug">
									{event.teamHome.name}
								</span>
							</div>
							<div className="text-[#818181] text-xs font-bold font-bahnschrift leading-snug">
								x
							</div>
							<div className="flex flex-row items-center gap-2">
								<span className="text-custom-black text-xs font-bold font-bahnschrift leading-snug">
									{event.teamAway.name}
								</span>
								<img
									className="w-10 h-10 object-scale-down"
									src={event.teamAway.image}
									alt={event.teamAway.name}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	};
	const Rules = () => {
		if (!data) return null;
		return (
			<div
				className="w-full h-full z-0 bg-[#f4f4f4] p-2 rounded-lg"
				dangerouslySetInnerHTML={{
					__html: data.rule.content,
				}}
			/>
		);
	};
	const Participants = () => {
		if (!data) return null;

		const allparticipants = enroll.map((league: any) => league.leagueName);

		return (
			<div className="flex flex-col gap-2">
				{participants
					.filter((participant: any) =>
						allparticipants.includes(participant.league)
					)
					.filter(
						(participant: any, index: any, self: any) =>
							index ===
							self.findIndex(
								(p: any) => p.nick === participant.nick
							)
					)
					.map((player: any, index: number) => (
						<div
							key={index}
							className="h-10 border-b border-[#979797] justify-start items-center gap-2 inline-flex"
						>
							{player.image ? (
								<img
									className="w-8 h-8 rounded-full"
									src={player.imagem}
								/>
							) : (
								<div className="w-8 h-8 rounded-full text-white font-bahnschrift text-xs bg-primary items-center justify-center flex">
									{transformInitial(player.nick)}
								</div>
							)}
							<div className="text-custom-black text-sm font-normal font-bahnschrift leading-tight">
								{player.nick}
							</div>
						</div>
					))}
			</div>
		);
	};

	const handleSelectOption = useCallback((newOption: any) => {
		setOptionsSelect((prevOptions: any) => {
			const existingOptionIndex = prevOptions.findIndex(
				(option: any) => option.optionId === newOption.optionId
			);
			if (existingOptionIndex !== -1) {
				if (newOption.delete) {
					return prevOptions.filter(
						(option: any) => option.optionId !== newOption.optionId
					);
				} else {
					const updatedOptions = [...prevOptions];
					updatedOptions[existingOptionIndex] = newOption;
					return updatedOptions;
				}
			} else {
				return [...prevOptions, newOption];
			}
		});
	}, []);

	return (
		<Layout>
			<div className="px-4 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between py-6 lg:max-w-screen-xl lg:mx-auto lg:pt-4">
					<ButtonBack classNameIcon="" />
					<p className="text-xl font-semibold text-custom-gray1 font-bahnschrift"></p>
					<div></div>
				</div>
				{loading ? (
					<div className="flex items-center justify-center">
						<RiRestartLine className="mr-2 h-4 w-4 animate-spin" />
					</div>
				) : (
					<div className="flex flex-col gap-2">
						<div className="w-full h-[196px]">
							{data?.content.image ? (
								<img
									className="lg:h-80 w-full h-full object-cover z-0"
									alt={data.content.title}
									src={data.content.image}
								/>
							) : (
								<div
									className="w-full h-full z-0"
									dangerouslySetInnerHTML={{
										__html: data?.content.html,
									}}
								/>
							)}
						</div>
						<Carousel
							data={data?.leaguesTournament.sort(
								(a: any, b: any) => {
									return a.sequence - b.sequence;
								}
							)}
						/>
						<TabsInLine initialPage="Perguntas" render={tabsData} />
					</div>
				)}
			</div>
		</Layout>
	);
};
