import { useEffect, useState } from 'react';
import { useTokenStore } from '../../../../store/token';
import { generatePrivateKey, sentryCapture } from '../../../../helpers/utils';
import { SentryError } from '../../../../types/enums';
import { useApi } from '../../../../hooks/useApi';
import { TournamentData } from '../../../../types/types';
import Tags from '../../../../components/ui/tags';
import CarouselTournament from '../../../../components/Carousel/Tournament';
import TabsInTournament from '../../../../components/TabsInTournament';
import { ResultsOptions } from '../ResultsOptions';
import { TableLeague } from '../../../../components/TableLeague';
import TabsButtonLeague from '../../../../components/TabsButtonLeague';
import Button from '../../../../components/ui/button';
import { TableEnrolls } from '../../../../components/TableEnrolls';
import CheckboxCustomIcon from '../../../../components/ui/CheckBoxCustom';
import { Games } from '../../../../components/Tournaments/Games';
import { RiCheckboxCircleFill, RiCheckLine } from '@remixicon/react';

interface iProps {
	tabsSelect: string;
	filter: any;
	onChangeFilter: (type: string, status: string) => void;
}

export const MyTournament = ({
	tabsSelect,
	filter,
	onChangeFilter,
}: iProps) => {
	const api = useApi();
	const token = useTokenStore((state: any) => state.token);
	const pageSize = 3;
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<TournamentData[]>([]);
	const [provabilyFair, setProvabilyFair] = useState(false);
	const [page, setPage] = useState(0);
	const [totalPage, setTotalPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		if (loading) return;
		fetchMyTournament(page);
	}, [page, filter.open, filter.finish]);

	useEffect(() => {
		if (loading) return;

		setHasMore(true);
		setData([]);
		setPage(0);
	}, [filter.open, filter.finish]);

	const fetchMyTournament = async (page: number) => {
		setLoading(true);
		try {
			if (totalPage !== 0 && page >= totalPage) {
				setHasMore(false);
				return;
			}
			const { open, finish } = filter;
			const res = await api.getMyTournament(
				{ pageSize, offset: page * pageSize, open, finish },
				token
			);
			if (!res?.success) {
				throw new Error(res.message);
			}

			setTotalPage(res.totalPages);
			setData((prev: any) => [...prev, ...res.data]);
			if (res.totalPages <= 1) {
				setHasMore(false);
			} else {
				setHasMore(true);
			}
		} catch (error: any) {
			sentryCapture(error, 'getTournament', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const Rules = ({ rule }: any) => {
		return (
			<div
				className="w-full h-full z-0 bg-[#f4f4f4] p-2 rounded-lg"
				dangerouslySetInnerHTML={{
					__html: rule.content,
				}}
			/>
		);
	};

	const thead = [
		{
			title: 'Inscrição',
		},
		{
			title: 'Liga',
		},
		{
			title: 'Pontos',
		},
		{
			title: 'Classificação',
		},
		{
			title: 'Prêmio',
		},
	];

	const Participants = ({ participants, leagues, tournamentStatus }: any) => {
		const leagueArray = leagues.map((item: any) => ({
			title: item.league.title,
			renderPage: () => (
				<TableLeague
					thead={thead}
					tbody={participants.filter(
						(league: any) => league.league === item.league.title
					)}
					tournamentStatus={tournamentStatus}
				/>
			),
		}));

		const render = [
			{
				title: 'Todos',
				renderPage: () => (
					<TableLeague
						thead={thead}
						tbody={participants}
						tournamentStatus={tournamentStatus}
					/>
				),
			},
			...leagueArray,
		];

		return (
			<div className="flex flex-col gap-2">
				<TabsButtonLeague initialPage="Todos" render={render} />
			</div>
		);
	};

	return (
		<div className="flex flex-col gap-6 mb-6">
			<div className="flex flex-row gap-4">
				<div className="h-5 flex-row justify-start items-start inline-flex">
					<div className="flex flex-row items-center gap-2">
						<CheckboxCustomIcon
							checked={filter.open === 'Aberto'}
							onCheckedChange={() => {
								if (loading) return;
								if (filter.open === '') {
									onChangeFilter('open', 'Aberto');
								} else {
									onChangeFilter('open', '');
								}
							}}
						/>
						<div
							onClick={() => {
								if (loading) return;
								if (filter.open === '') {
									onChangeFilter('open', 'Aberto');
								} else {
									onChangeFilter('open', '');
								}
							}}
							className="text-custom-gray10 text-sm font-normal font-bahnschrift leading-tight cursor-pointer"
						>
							Em Andamento
						</div>
					</div>
				</div>
				<div className="h-5 flex-row justify-start items-start inline-flex">
					<div className="flex flex-row items-center gap-2">
						<CheckboxCustomIcon
							checked={filter.finish === 'Fechado'}
							onCheckedChange={() => {
								if (loading) return;
								if (filter.finish === '') {
									onChangeFilter('closed', 'Fechado');
								} else {
									onChangeFilter('closed', '');
								}
							}}
						/>
						<div
							onClick={() => {
								if (loading) return;
								if (filter.finish === '') {
									onChangeFilter('closed', 'Fechado');
								} else {
									onChangeFilter('closed', '');
								}
							}}
							className="text-custom-gray10 text-sm font-normal font-bahnschrift leading-tight cursor-pointer"
						>
							Finalizados
						</div>
					</div>
				</div>
			</div>

			{data &&
				data.map((tournament, index) => (
					<div
						key={index}
						className="flex flex-col gap-2 bg-custom-white1 p-2.5 rounded-lg"
					>
						<div className="w-full bg-[#D4D4D4] flex flex-col gap-2 rounded-lg p-3">
							<Tags status={tournament.tournamentStatus} />
							<div className="w-full h-[196px]">
								{tournament?.content ? (
									tournament.content.image ? (
										<img
											className="lg:h-80 w-full h-full object-cover z-0"
											alt={tournament.content.title}
											src={tournament?.content.image}
										/>
									) : (
										<div
											className="w-full h-full z-0"
											dangerouslySetInnerHTML={{
												__html: tournament?.content
													.html,
											}}
										/>
									)
								) : (
									<></>
								)}
								<div
									onClick={() =>
										setProvabilyFair(!provabilyFair)
									}
									className="w-8 h-8 bg-[#17df6a] rounded-[68px] justify-center items-center float-right inline-flex relative bottom-9 right-2 cursor-pointer"
								>
									<RiCheckLine className="w-6 h-6 text-black" />
								</div>
							</div>
						</div>
						{provabilyFair && (
							<div className="px-4 py-2 bg-[#D4D4D4] rounded-lg flex-col justify-center items-start gap-2.5 inline-flex">
								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<div className="text-[#a2a5ab] text-[11px] font-semibold font-bahnschrift">
										Provably Fair
									</div>
									<RiCheckboxCircleFill className="w-3.5 h-3.5 relative text-[#449945]" />
								</div>
								<div className="justify-center items-center gap-2 inline-flex">
									<div>
										<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
											Chave gerada no servidor:
											<br />
										</span>
										<span className="text-neutral-800 text-[13px] font-semibold font-bahnschrift leading-snug">
											{generatePrivateKey()}
										</span>
									</div>
								</div>
								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<div>
										<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift leading-snug">
											Hash da chave gerada no servidor:
											<br />
										</span>
										<span className="text-neutral-800 text-[13px] font-semibold font-bahnschrift leading-snug">
											{generatePrivateKey()}
										</span>
									</div>
								</div>
								<div className="self-stretch justify-start items-center gap-2 inline-flex">
									<div>
										<span className="text-neutral-800 text-[13px] font-normal font-bahnschrift">
											Chave escolhida pelo usuário:
											<br />
										</span>
										<span className="text-neutral-800 text-[13px] font-semibold font-bahnschrift">
											{generatePrivateKey()}
										</span>
									</div>
								</div>
							</div>
						)}
						<CarouselTournament
							data={tournament?.leaguesTournament.sort(
								(a: any, b: any) => {
									return a.sequence - b.sequence;
								}
							)}
						/>

						<TableEnrolls
							thead={thead}
							tbody={tournament.participants.filter(
								(enroll: any) => enroll.reqIsPlayer
							)}
						/>

						<TabsInTournament
							initialPage=""
							render={[
								{
									title: 'Resultados',
									renderPage: () => (
										<ResultsOptions data={tournament} />
									),
								},
								{
									title: 'Jogos',
									renderPage: () => (
										<Games
											eventsTournament={
												tournament.eventsTournament
											}
										/>
									),
								},
								{
									title: 'Regras',
									renderPage: () => (
										<Rules rule={tournament.rule} />
									),
								},
								{
									title: 'Participantes',
									renderPage: () => (
										<Participants
											participants={
												tournament.participants
											}
											leagues={tournament?.leaguesTournament.sort(
												(a: any, b: any) => {
													return (
														a.sequence - b.sequence
													);
												}
											)}
											tournamentStatus={
												tournament.tournamentStatus
											}
										/>
									),
								},
							]}
						/>
					</div>
				))}

			{hasMore && (
				<Button
					isLoading={loading}
					onClick={() => {
						setPage(page + 1);
					}}
					className="w-full px-3.5 py-3.5"
				>
					Carregar mais
				</Button>
			)}
		</div>
	);
};
