import { useContext, useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { getVariant } from '../../../../helpers/variants';
import Icon from '../../../icons';
import { AuthContext } from '../../../../contexts/Auth/AuthContext';
import { SentryError } from '../../../../types/enums';
import { sentryCapture } from '../../../../helpers/utils';
import '../../../../styles/globals.css';
import Toast from '../../../ui/toast';

const LoginTokenForms = ({
	loginUrlAction,
	openModal,
	email,
	onClose,
}: any) => {
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [pinCode, setPinCode] = useState('');

	const handleLogin = async () => {
		setLoading(true);
		try {
			const login = await auth.signInToken(email, pinCode);
			if (!login?.success) {
				throw new Error(login.message);
			}
			toast(<Toast variant="success">Login efetuado com sucesso.</Toast>);
			onClose();
			if (loginUrlAction !== '') {
				navigate(loginUrlAction);
			}
		} catch (error: any) {
			toast(<Toast variant="error">{error.message}</Toast>);
			sentryCapture(error, 'handleLogin', SentryError.Error);
		} finally {
			setLoading(false);
		}
	};

	const handlePinChange = (pinCode: string) => {
		setPinCode(pinCode);
	};

	useEffect(() => {
		if (!openModal) {
			setPinCode('');
		}
	}, [openModal]);

	return (
		<div className="flex-col justify-center">
			<p className="text-2xl font-bold text-left text-custom-black pb-2 font-bahnschrift">
				Informe o código de acesso
			</p>

			<p className="text-xs text-left text-custom-black pb-2 font-bahnschrift">
				<span className="text-xs text-left text-custom-black">
					Enviamos o código de acesso para o e-mail{' '}
				</span>
				<span className="text-xs font-semibold text-left text-custom-black font-bahnschrift lowercase">
					{email}
				</span>
			</p>
			<div className="flex flex-col items-center justify-around">
				<VerificationInput
					classNames={{
						container: 'inputTokenContainer',
						character: 'inputTokenCharacter',
						characterInactive: 'inputTokenInactive',
						characterSelected: 'inputTokenActive',
						characterFilled: 'inputTokenFilled',
					}}
					placeholder=""
					inputProps={{
						inputMode: 'tel',
					}}
					length={6}
					value={pinCode}
					autoFocus={true}
					onChange={handlePinChange}
				/>
			</div>

			{loading ? (
				<div className={`px-5 py-3 mt-5 w-full ${getVariant('')}`}>
					<Icon name="Loader" classname="w-4 h-4 animate-spin" />
				</div>
			) : (
				<button
					type="button"
					onClick={handleLogin}
					className={`px-5 py-3 mt-5 w-full font-bahnschrift ${getVariant(
						''
					)}`}
				>
					Entrar
				</button>
			)}
		</div>
	);
};

export default LoginTokenForms;
