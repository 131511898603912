import Footer from '../../../components/Footer';
import { Layout } from '../../../components/layout';
import ButtonBack from '../../../components/ui/buttonBack';

export const Rules = () => {
	return (
		<Layout customBG="bg-background-dark" logoWhite>
			<div className="px-3.5 pt-7 pb-7 lg:max-w-screen-xl lg:mx-auto">
				<div className="items-center flex-row flex justify-between px-3.5 pt-10 pb-6 lg:flex-col lg:justify-start lg:items-start lg:gap-4">
					<ButtonBack classNameIcon="text-custom-white" />
					<p className="text-xl font-semibold text-center font-bahnschrift text-custom-white lg:text-primary lg:text-left">
						Regras de Utilização
					</p>
					<div></div>
				</div>

				<div className="text-sm text-left text-custom-gray4 font-bahnschrift lg:rounded-lg lg:bg-custom-gray2 lg:p-8">
					<p className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
						<span className="h-6 text-base font-bold text-left text-custom-white font-bahnschrift">
							Lorem ipsum dolor sit amet consectetur.{' '}
						</span>
					</p>
					Lorem ipsum dolor sit amet consectetur. At quam orci sed vel
					dolor vitae mattis. Tincidunt elit nulla suscipit duis sit
					adipiscing magna mi. Volutpat id ullamcorper egestas
					bibendum gravida. Morbi adipiscing odio eget semper nascetur
					aliquet venenatis nulla. Varius in vehicula eget sed dui in.
					Bibendum volutpat tincidunt id praesent elit placerat. Nam
					ultricies non sit consequat arcu. Aliquam rhoncus at aenean
					quis. Ac mattis id leo sit netus.
					<br />
					<br />
					Ut cras tempus elementum vestibulum in potenti. Quis et
					turpis volutpat purus praesent pellentesque. Non lorem
					tristique sed dolor varius sodales id tellus. Faucibus a et
					leo ante dictumst. Commodo adipiscing euismod diam
					consectetur.
					<br />
					Quis quis vitae vel enim hendrerit viverra morbi vulputate
					non. Lobortis interdum ac vestibulum rhoncus pharetra non
					ornare blandit. A ut tempor lobortis curabitur eget non
					tempor nunc elit. Scelerisque sit diam arcu metus sit cursus
					non. Arcu lectus quis nulla id tellus enim. Enim risus
					pharetra porta sit velit eu tempor eget. Arcu vel luctus sed
					sit tempus. <br />
					<br />
					Tortor adipiscing lobortis nunc mauris accumsan. Ultricies
					vivamus purus pharetra enim accumsan duis tincidunt
					adipiscing massa. Vitae sed sed nisl volutpat faucibus
					lobortis pulvinar erat blandit. At neque ac ornare velit
					morbi morbi curabitur mi. Lobortis nunc euismod mauris lorem
					arcu ornare tellus blandit. Et nulla in et aenean elementum
					urna ut.
					<br />
					<br />
					At hendrerit purus vel sit. Et lectus mi ridiculus porta
					nunc blandit suspendisse pellentesque tortor. Magna bibendum
					et vel lectus iaculis vel. Ac sit ut enim non lacus.
					Tincidunt ut enim sem elit nibh tortor mauris massa amet.
					Etiam turpis vel ac malesuada. Nisi sit tempor fermentum
					quis libero rhoncus ullamcorper duis a. Egestas sit at purus
					urna hendrerit eu aliquam mollis. Porttitor nunc neque id
					arcu pellentesque.
				</div>
			</div>

			<Footer />
		</Layout>
	);
};
